<!-- 应用列表切割 -->
<template>
    <div class="insight" ref="insight">
      <el-row>
        <el-col :style="{ width: `${(100 / splitNum) - 1}%`,margin:'0.5%'}" v-for="(item, index) in tableData" :key="index">
          <el-card shadow="hover" class="listBox" :class="{ active: item.isCurrentCompany == '1' } ">
            <div class="title"> <span  @click="setCurrent(item)"> {{ item.name | capitalize }}</span>
              <i class="iconfont iconicon-17" @click="edit(item)" style=" color: #409eff;" v-auth='"company:edit"'></i>
              <i class="iconfont iconicon-05" @click="del(item)" style=" color: #409eff;" v-auth='"company:delete"'></i>
               <!-- v-if="item.userRole == '01'" -->
            </div>
            <div @click="setCurrent(item)">
              <p>
                  <span> 纳税人识别号：{{item.taxId}}</span>
                </p>
<!--              <div>
                  <span style="vertical-align: top"> 公司地址：</span>
                  <span style="display: inline-block;width: 82%;text-indent: 0px !important;">{{getAddress(item.address)}}{{item.detailAddress}}</span>
                </div>-->
              <p>
                <span :title="getDetailAddress(item.address,item.detailAddress)"> 公司地址：{{getDetailAddress(item.address,item.detailAddress) | capitalize }}</span>
              </p>
              <p>
                  <span> 公司电话：{{item.phone}}</span>
                </p>
              <p>
                  <span :title=item.bankName> 开户行：{{item.bankName | capitalize }}</span>
                </p>
              <p>
                  <span :title=item.bankAccountNo> 银行账号：{{item.bankAccountNo | capitalize }}</span>
                </p>
              <img v-if="item.isCurrentCompany == '1'" src="@/assets/company/current.png" alt="" class="current">
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="pagination-container">
        <my-pagination :total='total' :pageSize='pageSize' @current-events="handleCurrentChange"
        @size-events="changeSize" :page='pageNum' :pageSizeList='pageSizeList'> </my-pagination>
      </div>
<!--      <delte-dialog :visible='deleteVis' :cancelDialog='cancelDialog' :confirmDialog='confirmDialog' :rowData='rowData' :isdeling='isdeling'/>-->
    </div>
</template>
<script>
  import {CodeToText } from 'element-china-area-data'
  import delteDialog from '@/views/install/company/modules/dialog/delte'
  export default {
    name: 'splitApply',
    components:{
      delteDialog,
    },
    props: {
      tableData: { //表格数据
        type: Array,
        default: function () {
          return []
        }
      },
      splitNum: { // 布局切割
        type: Number,
        default: function () {
          return 3
        }
      },
      page: {  // 分页 当前页数
        type: Number,
        default: function () {
          return 1
        }
      },
      pageSize: { // 分页 当前条数
        type: Number,
        default: function () {
          return 20
        }
      },
      total: { // 分页总数
        type: Number,
        default: function () {
          return 0
        }
      },
      selectionType: {
        type: String
      },
      expand: {
        type: Boolean
      }
    },
    filters: {
      capitalize: function (value) {
        if (value !== undefined && value.length > 15) {
          return value.substring(0, 15) + '...'
        }
        return value
      },
    },
    data() {
      return {
        currentCompanyId: this.getToken('companyId'),
        pageSizeList:['20','50','100','200'],
        pageNum: this.page,
        currentDate: new Date(),
        firstPageBtnDisabled: true,
        lastPageBtnDisabled: false,
        lastPageNum: 0,
        deleteVis:false,
        isdeling:false,
        rowData:{},
      };
    },
    mounted() {
        this.lastPageBtnDisabled = this.page ===  Math.ceil(this.total / this.pageSize) ? true : false;
        console.log(this.page,Math.ceil(this.total / this.pageSize));
    },
    methods: {
      setCurrent(data){
        this.$store.dispatch("SetCurrent", {userId: this.getToken('userId'),companyId:data.id}).then(res => {
            if (res.success) {
              // this.setToken('companyId', data.id);
              this.$store.dispatch('SetCompanyId',data.id);
              this.$emit("current-events", this.page);
              window.location.reload();
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
          })
      },
      delConfrim(item){
        this.$store.dispatch('CompanyDel',{'companyId':item.id}).then((res) => {
          if(res.success){
            einvAlert.success("提示",'删除成功')
            if(item.id === this.currentCompanyId){
              this.setToken('companyId','')
              this.getCurrentCompany()
            }
            if(this.tableData.length==1){
              this.$router.push('/home')
            }else{
              setTimeout(() => {
                window.location.reload();
              }, 1000)
            }
          }else if(res.businessSuccess){
            einvAlert.warning('提示',res.msg,true,'确定')
          }else{
            einvAlert.error("提示",res.msg)
          }
        }).catch(err=>{
          //  einvAlert.error("提示",err)
        })
      },
      edit(data){
        this.$router.push('/install/company/edit?id='+data.id)
      },
      del(item){
        let  _this=this;
        einvAlert.confirm(function(){
          _this.delConfrim(item);
        },"请确认是否删除?",'确定','取消');

      },
      getCurrentCompany(){
        this.$store.dispatch("GetCurrent", {
          userId: this.getToken('userId'),
        }).then(res => {
          if (res.success) {
            this.setToken('companyId',res.data.id)
          }
        }).catch(err=>{

        })
      },
      cancelDialog(){
        this.deleteVis = false;
      },
      getDetailAddress(address,detail){
        if(address == undefined){
           address='';
        }
        if(detail == undefined){
          detail='';
        }
        if(address==''){
          return address+detail;
        }else{
          return this.getAddress(address)+detail;
        }
      },
      getAddress(address){
        if(address ==='' || address == null){
          return ;
        }
        var arr = address.split(',')
        return CodeToText[arr[0]]+ CodeToText[arr[1]]+ CodeToText[arr[2]]
      },
      // 切换表格分页
      changeSize(size) {
        this.$parent.pageSize = size;
        this.$parent.getList();
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.$emit("current-events", val);
        this.$emit("save-current", val);
      }
    },
    watch: {
    }
  }
</script>

<style lang="less" scoped>
  .insight {
    overflow-y: hidden;
    min-height: 696px;
    .right_content {
      flex: 1;
      overflow-x: hidden;
      overflow-y: hidden;
      .main_content {
        padding: 16px 20px;
        height: calc(100% - 60px);
        overflow-y: auto;
      }
    }
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    padding: 0px 12px;
    background-color: #f4f4f5;
    color: #606266;
    min-width: 30px;
    border-radius: 4px;
    border: 1px solid #dddddd;
  }

  .listBox {
    height: 260px;
    list-style: none;
    background: #ffffff;
    position: relative;
    transition: all 0.2s linear;
    cursor: pointer;
    font-size: 15px;
    text-indent: 16px;
    .title{
      opacity: 1;
      background: #e5eef7;
      border-radius: 8px 8px 0px 0px;
      position: relative;
      height: 60px;
      color: #ccc;
      font-size: 18px;
      margin: 0;
      line-height: 55px;
      border-bottom: 1px solid #e6e6e6;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      text-align: left;
      color: #222222;
      white-space: nowrap;
      transition: all 0.5s linear;
      display: flex;
      justify-content : space-between ;
      padding-right: 18px;
      span{
        width: 100%;
      }
    }
    .titleTips{
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      i{
        margin-right: 16px;
      }
    }
    .type{
      padding: 8px 9px;
      font-size: 14px;
      background: #f2f3f4;
      border-radius: 8px;
      margin-left: 16px;
    }
    .time {
      font-size: 15px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      color: #666666;
      margin: 15px 0 10px;
      transition: all 0.2s linear;
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .ellipsisOne {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .service {
      font-size: 15px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      color: #333333;
      transition: all 0.2s linear;
    }
  }
  .el-popper[x-placement^=bottom] {
    margin-top: -0.375rem;
  }
  .el-dropdown-menu--medium .el-dropdown-menu__item{
    padding: 0 21px;
  }
  .listBox:hover {
    border-color: #4F71FF;
  }
  .pagination-container {
    float: right;
    margin: 0rem 0 1rem;
  }
  .el-pagination {
    display: inline-block;
    margin-top: 16px;
  }
  .first-pager,
  .last-pager {
    border: 1px solid #dddddd;
  }
  .pageNum {
    color: #227fff;
    font-size: 14px;
    font-style: initial;
    cursor: pointer;
  }
  .current{
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .active{
    border: 1px solid #4F71FF;
    .title{
      border-color: #4F71FF;
    }
  }
</style>
