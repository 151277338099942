<template>
  <div class="multipleTable">
    <split-apply ref="split"  :splitNum='splitNum' :tableData='data' :pageSize='pageSize' :total='total' @current-events="getList" @save-current="savePage" v-if="listStatus " />
    <nodata :message="'暂无相关账套'" h="400" v-else />
  </div>
</template>

<script>
  import SplitApply from '@/components/splitApply';
  export default {
    props: ['value'],
    components: {
      SplitApply,
    },
    data() {
      return {
        splitNum: 4,
        //分页查询
        page: 1,
        data: [],
        total: 0,
        pageSize: 20,
        current: '',

        //模态等待框
        listStatus: true,
        isLoading: false,  // 相关数据展示
      };
    },
    created() {

    },
    methods: {
      savePage(val) {
        this.current = val
        this.page = val
      },
      getList(val) {
        this.isLoading = true
        this.$store.dispatch("FindCompanyList", {
          name: this.value,
          userId: this.getToken('userId'),
          page: val ? val : this.page,
          rows: this.pageSize,
        }).then(res => {
          this.isLoading = false
          if (res.success) {
            /*if(res.data.count > 0){this.listStatus = true;}else{this.listStatus = false;}*/
            this.data = res.data;
            this.$emit('doInitAccountList',this.data);
            this.$emit('doChangeMenuDisabled',false);
            this.total = res.totalCount;
          } else {
            this.listStatus = false
            einvAlert.error('提示',res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },
    },
    mounted() {
      this.getList();
      // var _this = this
      // var id =setInterval(function (){
      //   var companyId = _this.getToken('companyId')
      //   if(companyId){
      //     _this.$refs.split.currentCompanyId = companyId
      //     _this.getList();
      //     clearInterval(id)
      //   }
      // },100)
    },
  }
</script>

<style lang="less" scoped>
  .multipleTable {
    margin: 0 auto;
    min-height: 696px;
    overflow: hidden;
    .apply {
      margin-top: 12px;
      width: 100%;
    }
  }
</style>
